import React, { useRef } from 'react';
import useClickAwayListener from '@/src/hooks/useClickAwayListener';
import { GlobalHeader } from '@/src/payload-types';
import styles from './Header.module.scss';
import Button from '../ui/Button';

interface IHeaderProps {
  theme?: 'dark' | 'light';
  desktopMenu: boolean;
  handleClickAway: () => void;
  headerData: GlobalHeader;
  buttonRef: React.RefObject<any>;
}

const HeaderDesktopMenu: React.FC<IHeaderProps> = ({
  theme,
  desktopMenu,
  handleClickAway,
  headerData,
  buttonRef,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickAwayListener(ref, (e) => {
    if (desktopMenu) {
      if (buttonRef.current && !buttonRef.current.contains(e.target)) {
        handleClickAway();
      }
    }
  });

  return (
    <div
      className={`${styles.desktopMenuContainer} ${desktopMenu ? styles.desktopMenuOpen : ''} ${theme === 'dark' ? styles.dark : ''}`}
      ref={ref}
    >
      <div className={styles.desktopMenuColumn}>
        <h6 className={`h6 ${styles.desktopMenuTitle}`}>Strategy</h6>
        {headerData?.ourServicesDropdown?.strategyPages?.map(
          (p) =>
            p?.page &&
            typeof p?.page !== 'string' && (
              <h3 key={p?.id}>
                <Button
                  $buttonType={'primary'}
                  href={p?.page?.slug?.startsWith('/') ? p?.page?.slug : '/' + p.page?.slug}
                  style={{ padding: '8px' }}
                  className={styles.desktopMenuItem}
                >
                  {p?.page?.name}
                </Button>
              </h3>
            ),
        )}
      </div>
      <div className={styles.desktopMenuColumn}>
        <h6 className={`h6 ${styles.desktopMenuTitle}`}>Product Design</h6>
        {headerData?.ourServicesDropdown?.productDesignPages?.map(
          (p) =>
            p?.page &&
            typeof p?.page !== 'string' && (
              <h3 key={p?.id}>
                <Button
                  $buttonType={'primary'}
                  href={p?.page?.slug?.startsWith('/') ? p?.page?.slug : '/' + p.page?.slug}
                  style={{ padding: '8px' }}
                  className={styles.desktopMenuItem}
                >
                  {p?.page?.name}
                </Button>
              </h3>
            ),
        )}
      </div>
      <div className={styles.desktopMenuColumn}>
        <h6 className={`h6 ${styles.desktopMenuTitle}`}>Engineering</h6>
        {headerData?.ourServicesDropdown?.engineeringPages?.map(
          (p) =>
            p?.page &&
            typeof p?.page !== 'string' && (
              <h3 key={p?.id}>
                <Button
                  $buttonType={'primary'}
                  href={p?.page?.slug?.startsWith('/') ? p?.page?.slug : '/' + p.page?.slug}
                  style={{ padding: '8px' }}
                  className={styles.desktopMenuItem}
                >
                  {p?.page?.name}
                </Button>
              </h3>
            ),
        )}
      </div>
    </div>
  );
};

export default HeaderDesktopMenu;
