import { MediumMediaQuery, LargeMediaQuery } from './responsive-breakpoints';

export const BackgroundGradientStyle = (theme: 'dark' | 'light') => {
  return `
    content: '';
    position: fixed;
    z-index: 0;
    background: ${theme === 'dark' ? 'var(--background-gradient-dark)' : 'var(--background-gradient)'};
    filter: blur(2px);
  `;
};

export const BackgroundGradientStyleResponsive = (position: 'before' | 'after') => `
  width: var(--background-gradient-size-small);
  height: var(--background-gradient-size-small);
  border-radius: var(--background-gradient-size-small);
  ${position == 'before' ? 'top' : 'bottom'}: calc(-1 * var(--background-gradient-size-small) / 2);
  ${position == 'before' ? 'left' : 'right'}: calc(-1 * var(--background-gradient-size-small) / 2);

  ${MediumMediaQuery} {
    width: var(--background-gradient-size-medium);
    height: var(--background-gradient-size-medium);
    border-radius: var(--background-gradient-size-medium);
    ${position == 'before' ? 'top' : 'bottom'}: calc(-1 * var(--background-gradient-size-medium) / 2);
    ${position == 'before' ? 'left' : 'right'}: calc(-1 * var(--background-gradient-size-medium) / 2);
  }

  ${LargeMediaQuery} {
    width: var(--background-gradient-size-large);
    height: var(--background-gradient-size-large);
    border-radius: var(--background-gradient-size-large);
    ${position == 'before' ? 'top' : 'bottom'}: calc(-1 * var(--background-gradient-size-large) / 2);
    ${position == 'before' ? 'left' : 'right'}: calc(-1 * var(--background-gradient-size-large) / 2);
  }
`;
