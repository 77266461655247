'use client';
import { useEffect, useRef, useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { usePathname } from 'next/navigation';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderDesktopMenu from '@/src/components/header/HeaderDesktopMenu';
import { GlobalHeader } from '@/src/payload-types';
import { CONTACT_US_SLUG, INSIGHT_SLUG } from '@/src/constants/route-constants';
import { useThemeContext } from '@/src/contexts/theme/ThemeContext';
import styles from './Header.module.scss';
import Image from 'next/image';
import Button from '../ui/Button';
import { MEDIUM_VIEWPORT_MIN, MediumImageQuery } from '@/src/styles/responsive-breakpoints';
import Logo from '../../assets/svg/Logo.svg';
import LogoWhite from '../../assets/svg/LogoWhite.svg';
import Link from 'next/link';
import { debounce } from 'next/dist/server/utils';

interface IHeaderProps {
  headerData: GlobalHeader;
}

const Header: React.FC<IHeaderProps> = ({ headerData }) => {
  const prevScrollTop = useRef(0);
  const headerRef = useRef<null | HTMLElement>(null);
  const ourServicesButtonRef = useRef(undefined);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [desktopMenu, setDesktopMenu] = useState(false);

  const isHomepage = useRef(false);
  const isAboutusPage = useRef(false);

  const pathname = usePathname();
  const { theme } = useThemeContext();

  useEffect(() => {
    // prevents the dropdown menu from staying open on navigations
    mobileMenu && setMobileMenu(false);
    desktopMenu && setDesktopMenu(false);

    switch (pathname) {
      case '/':
        isHomepage.current = true;
        isAboutusPage.current = false;
        break;
      case '/about-us':
        isAboutusPage.current = true;
        isAboutusPage.current = false;
        break;
      default:
        isAboutusPage.current = false;
        isHomepage.current = false;
    }

    const scrollContainer = document.getElementById('scrollContainer');
    if (scrollContainer && pathname === '/') {
      scrollContainer.style.transform = 'translateY(0)';
    } else if (scrollContainer && pathname !== '/') {
      scrollContainer.style.transform = `translateY(${window.innerWidth < MEDIUM_VIEWPORT_MIN ? 'var(--spacing-header-small)' : 'var(--spacing-header-big)'})`;
    }

    return () => {
      const headerElem = document.querySelector('.header-inner-container');
      // @ts-ignore
      if (headerElem) headerElem.style.background = null;
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const scrollContainer = document.getElementById('scrollContainer');

    const onScroll = (e: Event) => {
      const target = e.target as Element;
      const scrollTop = target?.scrollTop || 0;
      const scrollHeight = scrollContainer?.scrollHeight || 0;

      if (headerRef.current && scrollContainer) {
        if (scrollTop > 10 && scrollTop > prevScrollTop.current && window.innerWidth < MEDIUM_VIEWPORT_MIN) {
          headerRef.current.style.transform = 'translateY(-100%)';
          scrollContainer.style.transform = 'translateY(0)';
        } else if (
          scrollTop < scrollHeight - window.innerHeight - 200 &&
          scrollTop < prevScrollTop.current &&
          (!isHomepage.current || !isAboutusPage.current || window.innerWidth < MEDIUM_VIEWPORT_MIN)
        ) {
          headerRef.current.style.transform = 'translateY(0)';
          scrollContainer.style.transform =
            window.innerWidth < MEDIUM_VIEWPORT_MIN
              ? 'translateY(var(--spacing-header-small))'
              : 'translateY(var(--spacing-header-big))';
        }
      }
      prevScrollTop.current = scrollTop;
    };
    scrollContainer?.addEventListener('scroll', onScroll);
    return () => {
      scrollContainer?.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onClickMobileMenu = debounce((e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const headerElem = document.querySelector('.header-inner-container');
    if (headerElem != null) {
      // @ts-ignore
      headerElem.style.transition = mobileMenu ? '1.2s' : '0.1s';
      // @ts-ignore
      headerElem.style.background = mobileMenu
        ? 'transparent'
        : theme === 'dark'
          ? 'var(--background-dark)'
          : 'var(--background)';
    }
    setMobileMenu((mobileMenu) => !mobileMenu);
  }, 100);

  const onClickServices = () => {
    setDesktopMenu((prev) => !prev);
  };

  return (
    <header>
      <nav className={`header-container ${styles.headerContainer} ${theme}`} ref={headerRef}>
        <div className={`header-inner-container ${styles.headerInnerContainer}`}>
          {theme && (
            <h1>
              <Link href={'/'}>
                <Image
                  src={theme === 'dark' ? LogoWhite : Logo}
                  alt={'Vault Logo'}
                  priority
                  sizes={`80px, ${MediumImageQuery} 114px`}
                  className={styles.headerLogo}
                />
              </Link>
            </h1>
          )}
          <div className={styles.centerContainer}>
            <h2>
              <Button
                className={styles.buttonLink}
                $buttonType={'primary'}
                size={'regular'}
                href={'/about-us'}
              >
                {'About Us'}
              </Button>
            </h2>
            <h2>
              <Button
                $buttonType={'primary'}
                $isActive={desktopMenu}
                onClick={onClickServices}
                size={'regular'}
                ref={ourServicesButtonRef}
              >
                {'Our Services'}
                {desktopMenu ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
              </Button>
            </h2>
            <h2>
              <Button
                className={styles.buttonLink}
                $buttonType={'primary'}
                size={'regular'}
                href={`/${INSIGHT_SLUG}`}
              >
                Insights
              </Button>
            </h2>
          </div>
          <Button
            $buttonType={'outlined'}
            href={`/${CONTACT_US_SLUG}`}
            className={styles.contactUsButton}
            size={'regular'}
            style={{ height: 54 }}
          >
            Contact Us
          </Button>
          <Button $buttonType={'primary'} onClick={onClickMobileMenu} className={styles.closeButton}>
            <div
              className={`${styles.animatedBar} ${theme === 'dark' ? styles.dark : ''} ${mobileMenu ? styles.animatedBarActive : ''}`}
            />
            <div
              className={`${styles.animatedBar} ${theme === 'dark' ? styles.dark : ''} ${mobileMenu ? styles.animatedBarActive : ''}`}
            />
            <div
              className={`${styles.animatedBar} ${theme === 'dark' ? styles.dark : ''} ${mobileMenu ? styles.animatedBarActive : ''}`}
            />
          </Button>
        </div>
        <HeaderDesktopMenu
          theme={theme}
          desktopMenu={desktopMenu}
          handleClickAway={onClickServices}
          headerData={headerData}
          buttonRef={ourServicesButtonRef}
        />
        <HeaderMobileMenu theme={theme} mobileMenu={mobileMenu} headerData={headerData} />
      </nav>
    </header>
  );
};

export default Header;
