export const INSIGHT_SLUG = 'insights';
export const CATEGORY_SLUG = 'category';
export const PAGES_SLUG = 'pages';
export const CONTACT_US_SLUG = 'contact';
export const ABOUT_US_SLUG = 'about-us';
export const OG_SLUG = 'og-images';
export const CATCH_ALL_SLUG = '[...slug]';

export const CACHE_CONTROL_MAX_AGE = 1200;

export const SLUGS_TO_INVALIDATE = [
  // Want to avoid invalidating all routes ( /* ) in Cloudfront. Normal site pages are handled with logic in the endpoint since they do not have a consistent prefix
  `/`,
  `/${INSIGHT_SLUG}`,
  `/${INSIGHT_SLUG}/*`,
  `/${OG_SLUG}/*`,
  `/${CONTACT_US_SLUG}`,
  `/${ABOUT_US_SLUG}`,
];
