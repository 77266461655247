'use client';

import styled, { css } from 'styled-components';
import React, { JSX } from 'react';
import { Headline6 } from '@/src/styles/shared-styles';
import Link from 'next/link';

const footerLinkTextStyle = css`
  color: var(--white-primary, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: -0.5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  white-space: pre-wrap;
`;

export const FooterLinkAnchor = styled(Link)`
  ${footerLinkTextStyle}

  &:hover {
    color: var(--purple-primary, #fff);
  }
`;

export const FooterLinsksGroupHeaderText = styled(Headline6)`
  color: white;
`;

interface IProps {
  linkText: string;
  linkUrl: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
}

export const FooterComedyMaskSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1823_12557" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1823_12557)">
      <path
        d="M10 14.167C10.8333 14.167 11.559 13.8962 12.1771 13.3545C12.7951 12.8128 13.1667
          12.1392 13.2917 11.3337C13.3194 11.1948 13.2882 11.0767 13.1979 10.9795C13.1076 10.8823
          12.9861 10.8337 12.8333 10.8337H7.16667C7.02778 10.8337 6.90972 10.8823 6.8125 10.9795C6.71528
          11.0767 6.68056 11.1948 6.70833 11.3337C6.83333 12.1392 7.20486 12.8128 7.82292 13.3545C8.44097
          13.8962 9.16667 14.167 10 14.167ZM10 18.3337C8.95833 18.3337 7.98264 18.1357 7.07292
          17.7399C6.16319 17.3441 5.37153 16.8094 4.69792 16.1357C4.02431 15.4621 3.48958 14.6705
          3.09375 13.7607C2.69792 12.851 2.5 11.8753 2.5 10.8337V3.33366C2.5 2.87533 2.66319 2.48296
          2.98958 2.15658C3.31597 1.83019 3.70833 1.66699 4.16667 1.66699H15.8333C16.2917 1.66699 16.684
          1.83019 17.0104 2.15658C17.3368 2.48296 17.5 2.87533 17.5 3.33366V10.8337C17.5 11.8753 17.3021
          12.851 16.9062 13.7607C16.5104 14.6705 15.9757 15.4621 15.3021 16.1357C14.6285 16.8094 13.8368
          17.3441 12.9271 17.7399C12.0174 18.1357 11.0417 18.3337 10 18.3337ZM10 16.667C11.6111 16.667
          12.9861 16.0975 14.125 14.9587C15.2639 13.8198 15.8333 12.4448 15.8333
          10.8337V3.33366H4.16667V10.8337C4.16667 12.4448 4.73611 13.8198 5.875 14.9587C7.01389
          16.0975 8.38889 16.667 10 16.667ZM7.5 5.83366C7.125 5.83366 6.79514 5.94477 6.51042
          6.16699C6.22569 6.38921 6.02778 6.66699 5.91667 7.00033C5.875 7.12533 5.89583 7.23991 5.97917
          7.34408C6.0625 7.44824 6.18056 7.50033 6.33333 7.50033H8.66667C8.80556 7.50033 8.91667 7.44824 9
          7.34408C9.08333 7.23991 9.11111 7.12533 9.08333 7.00033C8.97222 6.66699 8.77431 6.38921 8.48958
          6.16699C8.20486 5.94477 7.875 5.83366 7.5 5.83366ZM12.5 5.83366C12.125 5.83366 11.7951 5.94477
          11.5104 6.16699C11.2257 6.38921 11.0278 6.66699 10.9167 7.00033C10.875 7.12533 10.8958 7.23991
          10.9792 7.34408C11.0625 7.44824 11.1806 7.50033 11.3333 7.50033H13.6667C13.8056 7.50033 13.9167
          7.44824 14 7.34408C14.0833 7.23991 14.1111 7.12533 14.0833 7.00033C13.9722 6.66699 13.7743
          6.38921 13.4896 6.16699C13.2049 5.94477 12.875 5.83366 12.5 5.83366Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const FooterLink: React.FC<IProps> = ({ linkText, linkUrl, icon, style }) => {
  return (
    <FooterLinkAnchor href={linkUrl} style={style}>
      {icon}
      {linkText}
    </FooterLinkAnchor>
  );
};

export default FooterLink;
