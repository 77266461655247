'use client';

import styled from 'styled-components';
import { LargeMediaQuery, MediumMediaQuery, XLargeMediaQuery } from './responsive-breakpoints';
import Image from 'next/image';
import { BackgroundGradientStyle, BackgroundGradientStyleResponsive } from './style.util';

const PAGE_BACKGROUND_ANIMATION_DURATION = '30s';

export const PageBackdrop = styled.div<{ theme?: 'dark' | 'light' }>`
  background: ${(props) => (props.theme === 'dark' ? 'var(--background-dark)' : 'var(--background)')};
  min-width: 100vw;
  max-width: 100vw;
  position: relative;
  z-index: 1;

  overflow: hidden;

  &:before,
  &:after {
    ${(props) => BackgroundGradientStyle(props.theme)}
    animation-duration: ${PAGE_BACKGROUND_ANIMATION_DURATION};
    animation-timing-function: linear;
    pointer-events: none;
  }

  &:before {
    ${BackgroundGradientStyleResponsive('before')}
    animation-name: backgroundTopAnimation;
  }

  &:after {
    ${BackgroundGradientStyleResponsive('after')}
    animation-name: backgroundBottomAnimation;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding-top: var(--spacing-header-big);
  overflow: hidden;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--container-width-small);
  gap: var(--spacing-section-medium, 80px);
  padding: 0 var(--side-margin-small, 16px);
  background: transparent;
  z-index: 1;

  ${MediumMediaQuery} {
    width: var(--container-width-medium);
    padding: 0;
  }

  ${LargeMediaQuery} {
    width: var(--container-width-large);
  }

  ${XLargeMediaQuery} {
    width: var(--container-width-xl);
  }
`;

export const OutlinedToggleButton = styled.button<{ $isActive: boolean }>`
  border-radius: 100px;
  padding: 12px 16px 12px 16px;
  background: transparent;
  color: var(--neutral-700);
  border: solid 1px var(--neutral-700);
  text-align: center;
  white-space: nowrap;

  font-size: var(--font-size-button-regular);
  font-weight: var(--font-weight-button-regular);
  line-height: 32px; // TODO - is this an intentional oneoff from
  letter-spacing: var(--letter-spacing);

  &:hover {
    color: ${(props) => (props.$isActive ? 'var(--white-primary)' : 'var(--text-button-hover)')};
    border-color: var(--text-button-hover);
  }

  ${(props) => {
    if (props.$isActive) {
      return `
          border: solid 1px var(--purple-primary); 
          background: var(--purple-primary);
          color: var(--white-primary);
        `;
    }
    return '';
  }}
`;

export const Headline1 = styled.h1<{ color?: string }>`
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  line-height: var(--line-height-h1);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-header)')};
`;

export const Headline2 = styled.h2<{ color?: string }>`
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  line-height: var(--line-height-h2);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-header)')};
`;

export const Headline3 = styled.h3<{ color?: string }>`
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
  line-height: var(--line-height-h3);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-header)')};
`;

export const Headline4 = styled.h4<{ color?: string }>`
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
  line-height: var(--line-height-h4);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-header)')};
`;

export const Headline5 = styled.h5<{ color?: string }>`
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-header)')};
`;

export const Headline6 = styled.h6<{ color?: string }>`
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-h6);
  line-height: var(--line-height-h6);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-header)')};
`;

export const BodySmall = styled.p<{ color?: string }>`
  font-size: var(--font-size-body-small);
  font-weight: var(--font-weight-body-small);
  line-height: var(--line-height-body-small);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-body)')};
  white-space: pre-wrap;
`;

export const BodyRegular = styled.p<{ color?: string }>`
  font-size: var(--font-size-body-regular);
  font-weight: var(--font-weight-body-regular);
  line-height: var(--line-height-body-regular);
  letter-spacing: var(--letter-spacing);
  color: ${(props) => (props.color ? props.color : 'var(--text-body)')};
  white-space: pre-wrap;
`;

export const BodyLarge = styled.p<{ color?: string }>`
  font-size: var(--font-size-body-large);
  font-weight: var(--font-weight-body-large);
  line-height: var(--line-height-body-large);
  color: ${(props) => (props.color ? props.color : 'var(--text-body)')};
  letter-spacing: var(--letter-spacing);
  white-space: pre-wrap;
`;

export const ButtonLarge = styled.p<{ color?: string }>`
  font-size: var(--font-size-button-large);
  font-weight: var(--font-weight-button-large);
  line-height: var(--line-height-button-large);
  color: ${(props) => (props.color ? props.color : 'var(--text-body)')};
  letter-spacing: var(--letter-spacing);
  white-space: pre-wrap;
`;

export const CustomUnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;

  gap: var(--spacing-16);

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  li p {
    margin-left: 1rem;
  }
`;

export const CustomUnorderedListBullet = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: var(--black-primary);
  border-radius: 50%;
  margin: 0;
  padding: 0;
`;
