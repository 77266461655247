// app/ThemeContext.tsx
'use client';

import React, { createContext, useContext, useState } from 'react';

type Theme = 'light' | 'dark';

interface ThemeContextType {
  theme: Theme | undefined;
  renderHeader: boolean;
  renderFooter: boolean;
  setTheme: (theme: Theme) => void;
  setRenderHeader: (b: boolean) => void;
  setRenderFooter: (b: boolean) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({
  children,
  initialTheme,
}: {
  children: React.ReactNode;
  initialTheme?: Theme | undefined;
}) {
  const [theme, setTheme] = useState<Theme | undefined>(initialTheme);
  const [renderHeader, setRenderHeader] = useState<boolean>(true);
  const [renderFooter, setRenderFooter] = useState<boolean>(true);

  return (
    <ThemeContext value={{ theme, setTheme, renderHeader, renderFooter, setRenderHeader, setRenderFooter }}>
      {children}
    </ThemeContext>
  );
}

export function useThemeContext() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
}
