'use client';
import React from 'react';
import styles from './Button.module.scss';
import Link from 'next/link';
import { useThemeContext } from '@/src/contexts/theme/ThemeContext';

interface ButtonProps {
  $buttonType: 'primary' | 'secondary' | 'outlined';
  size?: 'regular' | 'large' | null;
  buttonTheme?: 'light' | 'dark' | null;
  $isActive?: boolean;
  isComplete?: boolean;
  disabled?: boolean;
  href?: string;
  inNewTab?: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
  id?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  ref?: React.RefObject<any>;
}

const Button: React.FC<ButtonProps> = ({
  $buttonType,
  size,
  buttonTheme,
  $isActive = false,
  isComplete = false,
  disabled = false,
  href,
  children,
  onClick,
  className,
  id,
  style,
  inNewTab,
  ref,
  type,
}) => {
  const { theme } = useThemeContext();

  const finalClasses = [
    href ? styles.styledButtonLink : styles.styledButton,
    styles[$buttonType],
    buttonTheme ? styles[buttonTheme] : theme ? styles[theme] : '',
    $isActive ? styles.active : '',
    isComplete ? styles.complete : '',
    size != null ? (size === 'large' ? styles.large : styles.regular) : '',
  ]
    .filter(Boolean)
    .join(' ');

  if (href) {
    return (
      <Link
        style={style}
        href={href}
        className={`${finalClasses} ${styles.link} ${className}`}
        target={inNewTab ? '_blank' : '_self'}
        ref={ref}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      style={style}
      className={`${finalClasses} ${className}`}
      disabled={disabled}
      onClick={onClick}
      id={id}
      ref={ref}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
