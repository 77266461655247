'use client';

import styled from 'styled-components';
import { LargeMediaQuery, MediumMediaQuery, XLargeMediaQuery } from '@/src/styles/responsive-breakpoints';
import { BodySmall } from '@/src/styles/shared-styles';
import Button from '../components/ui/Button';
import Image from 'next/image';

export const FooterBackdrop = styled.footer`
  background: var(--footer-background);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  transition: 1s;
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  padding: var(--spacing-40) var(--side-margin-small) var(--spacing-section-medium) var(--side-margin-small);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-section-small);
  background: var(--footer-background);

  ${MediumMediaQuery} {
    padding: var(--spacing-40) 0 var(--spacing-section-medium) 0;
    gap: var(--spacing-section-medium);
    width: var(--container-width-medium);
  }

  ${LargeMediaQuery} {
    padding: var(--spacing-section-medium) 0;
    width: var(--container-width-large);
  }

  ${XLargeMediaQuery} {
    padding: var(--spacing-section-medium) 0;
    width: var(--container-width-xl);
  }
`;

export const FooterDividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: white;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-section-small);
  align-self: stretch;
  width: 100%;

  ${LargeMediaQuery} {
    gap: unset;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const FooterSocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0;

  ${LargeMediaQuery} {
    gap: 32px;
  }
`;

export const FooterSocialLinksContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: var(--spacing-section-small);
  align-self: stretch;
  width: 100%;
  justify-content: space-between;

  ${MediumMediaQuery} {
    gap: var(--spacing-section-medium);
  }

  ${LargeMediaQuery} {
    flex-direction: row;
    gap: unset;
  }
`;

export const FooterLogoAndSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  margin-top: var(--spacing-64);

  ${MediumMediaQuery} {
    margin-top: var(--spacing-section-medium);
  }

  ${LargeMediaQuery} {
    margin-top: var(--spacing-section-large);
  }

  ${XLargeMediaQuery} {
    flex-direction: row;
  }
`;

export const FooterLinksBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  align-items: flex-start;
  ${LargeMediaQuery} {
    flex-direction: column;
  }
`;

export const FooterLinksGroup = styled(BodySmall)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: white;
  ${LargeMediaQuery} {
    flex-direction: row;
  }
`;

export const FooterLinksColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  align-items: flex-start;

  ${LargeMediaQuery} {
    gap: var(--spacing-24);
  }
`;

export const FooterLinksButton = styled(Button)`
  padding: 0;
  color: var(--white-primary) !important;
  font-size: var(--font-size-body-regular);
  font-weight: var(--font-weight-body-regular);
  line-height: var(--line-height-body-regular);
  letter-spacing: var(--letter-spacing);
  &:hover {
    color: var(--text-button-hover) !important;
  }
`;

export const FooterSocialLinksButton = styled(Button)`
  padding: 0;
  color: var(--white-primary) !important;
  font-size: var(--font-size-body-small);
  font-weight: var(--font-weight-body-small);
  line-height: var(--line-height-body-small);
  letter-spacing: var(--letter-spacing);
  flex-direction: row-reverse;
  gap: 8px;
  &:hover {
    color: var(--text-button-hover) !important;
    svg {
      color: var(--text-button-hover);
    }
  }
`;

export const FooterSocialLinksMobileButton = styled(FooterSocialLinksButton)`
  display: flex;
  ${LargeMediaQuery} {
    display: none;
  }
`;

export const FooterSocialLinksDesktopButton = styled(FooterSocialLinksButton)`
  display: none;
  ${LargeMediaQuery} {
    display: flex;
  }
  svg {
    color: var(--white-primary);
  }
`;

export const FooterLogoImage = styled(Image)`
  width: 114.1px;
  height: 25px;

  ${LargeMediaQuery} {
    width: 136.91px;
    height: 30px;
  }
`;
