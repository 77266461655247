export const SMALL_VIEWPORT_MIN = 0;
export const MEDIUM_VIEWPORT_MIN = 734;
export const LARGE_VIEWPORT_MIN = 1068;
export const X_LARGE_VIEWPORT_MIN = 1439;

const breakpoints = [SMALL_VIEWPORT_MIN, MEDIUM_VIEWPORT_MIN, LARGE_VIEWPORT_MIN, X_LARGE_VIEWPORT_MIN];
const [SmallMediaQuery, MediumMediaQuery, LargeMediaQuery, XLargeMediaQuery] = breakpoints.map(
  (bp) => `@media (min-width: ${bp}px)`,
);

const [SmallImageQuery, MediumImageQuery, LargeImageQuery, XLargeImageQuery] = breakpoints.map(
  (bp) => `(min-width: ${bp}px)`,
);

export {
  SmallMediaQuery,
  MediumMediaQuery,
  LargeMediaQuery,
  XLargeMediaQuery,
  SmallImageQuery,
  MediumImageQuery,
  LargeImageQuery,
  XLargeImageQuery,
};
