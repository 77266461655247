import React from 'react';
import { GlobalHeader } from '@/src/payload-types';
import { ABOUT_US_SLUG, CONTACT_US_SLUG, INSIGHT_SLUG } from '@/src/constants/route-constants';
import styles from './Header.module.scss';
import Button from '../ui/Button';

interface IHeaderProps {
  theme?: 'dark' | 'light';
  mobileMenu: boolean;
  headerData: GlobalHeader;
}

const HeaderMobileMenu: React.FC<IHeaderProps> = ({ theme, mobileMenu, headerData }) => {
  const renderDivider = (style?: React.CSSProperties) => (
    <div className={`${styles.mobileDivider} ${theme === 'dark' ? styles.dark : ''}`} style={style} />
  );

  return (
    <div
      className={`${styles.mobileMenuContainer} ${mobileMenu ? styles['mobile-menu-open'] : ''} ${theme === 'dark' ? styles.dark : ''}`}
    >
      <h2>
        <Button
          className={`${styles.mobileMenuButton} ${theme === 'dark' ? styles.dark : ''}`}
          $buttonType={'primary'}
          href={`/${ABOUT_US_SLUG}`}
        >
          About Us
        </Button>
      </h2>
      {renderDivider()}
      <h2>
        <Button
          className={`${styles.mobileMenuButton} ${theme === 'dark' ? styles.dark : ''}`}
          $buttonType={'primary'}
          style={{ pointerEvents: 'none' }}
        >
          Our Services
        </Button>
      </h2>
      {renderDivider()}
      <h6 className={`h6 ${styles.mobileMenuTitle}`} style={{ paddingTop: 16 }}>
        Strategy
      </h6>
      {headerData?.ourServicesDropdown?.strategyPages?.map(
        (p) =>
          p?.page &&
          typeof p?.page !== 'string' && (
            <h3 key={p?.id}>
              <Button
                $buttonType={'primary'}
                className={`${styles.mobileMenuSubButton} ${theme === 'dark' ? styles.dark : ''}`}
                href={p?.page?.slug?.startsWith('/') ? p?.page?.slug : '/' + p.page?.slug}
                style={{ paddingBottom: 8, lineHeight: '27px' }}
              >
                {p?.page?.name}
              </Button>
            </h3>
          ),
      )}
      <h6 className={`h6 ${styles.mobileMenuTitle}`}>Production Design</h6>
      {headerData?.ourServicesDropdown?.productDesignPages?.map(
        (p) =>
          p?.page &&
          typeof p?.page !== 'string' && (
            <h3 key={p?.id}>
              <Button
                $buttonType={'primary'}
                className={`${styles.mobileMenuSubButton} ${theme === 'dark' ? styles.dark : ''}`}
                href={p?.page?.slug?.startsWith('/') ? p?.page?.slug : '/' + p.page?.slug}
                style={{ paddingBottom: 8, lineHeight: '27px' }}
              >
                {p?.page?.name}
              </Button>
            </h3>
          ),
      )}
      <h6 className={`h6 ${styles.mobileMenuTitle}`}>Engineering</h6>
      {headerData?.ourServicesDropdown?.engineeringPages?.map(
        (p) =>
          p?.page &&
          typeof p?.page !== 'string' && (
            <h3 key={p?.id}>
              <Button
                $buttonType={'primary'}
                className={`${styles.mobileMenuSubButton} ${theme === 'dark' ? styles.dark : ''}`}
                href={p?.page?.slug?.startsWith('/') ? p?.page?.slug : '/' + p.page?.slug}
                style={{ paddingBottom: 8, lineHeight: '27px' }}
              >
                {p?.page?.name}
              </Button>
            </h3>
          ),
      )}
      {renderDivider({ marginTop: 16 })}
      <h2>
        <Button
          $buttonType={'primary'}
          href={`/${INSIGHT_SLUG}`}
          className={`${styles.mobileMenuButton} ${theme === 'dark' ? styles.dark : ''}`}
        >
          Insights
        </Button>
      </h2>
      {renderDivider()}
      <Button
        $buttonType={'outlined'}
        href={`/${CONTACT_US_SLUG}`}
        className={`${theme === 'dark' ? styles.dark : ''}`}
        style={{ padding: 16, marginTop: 24, alignSelf: 'center' }}
      >
        Contact us
      </Button>
    </div>
  );
};

export default HeaderMobileMenu;
