'use client';
import React, { createContext, useContext, useState } from 'react';
import { Blog, BlogCategory, BlogTag } from '../../payload-types';

export interface InsightsData {
  blogs: Blog[];
  blogTags: BlogTag[];
  blogCategories: BlogCategory[];
  featuredInsights: Blog[];
}
interface InsightsContextType {
  insightsData: InsightsData | undefined;
  refresh: () => void;
}

const InsightsContext = createContext<InsightsContextType | undefined>(undefined);

export function InsightsProvider({
  children,
  initialData,
  refreshData,
}: {
  children: React.ReactNode;
  initialData: InsightsData | undefined;
  refreshData: () => Promise<InsightsData>;
}) {
  const [insightsData, setInsightsData] = useState<InsightsData | undefined>(initialData);

  const refresh = () => {
    refreshData().then((data) => {
      setInsightsData(data);
    });
  };

  return <InsightsContext value={{ insightsData: insightsData, refresh }}>{children}</InsightsContext>;
}

export function useInsightsData() {
  const context = useContext(InsightsContext);
  if (context === undefined) {
    throw new Error('useInsightsData must be used within a InsightsProvider');
  }
  return context;
}
