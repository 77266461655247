import React, { useEffect } from 'react';

const useClickAwayListener = (ref: React.RefObject<any>, handleClickAway: (event: MouseEvent) => void) => {
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickAway(event);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleClickAway, ref]);
};

export default useClickAwayListener;
